/** @jsx jsx */
import { jsx } from "theme-ui";
// import { noop } from "sites-common/utils/lodash";

// import { DefaultButton } from "office-ui-fabric-react";
import DefaultLayout from "../../../layouts/dlw-mobile";
import KanhaVolunteering from "../../../components/AshramOffice/KanhaVolunteering";

const KanhaVolunteeringPage = () => {
  return (
    <DefaultLayout>
      {/* <DefaultButton text="My Entries" href="./my-entries/" />
      <DefaultButton text="New Entries" href="./new-entries/" /> */}

      <KanhaVolunteering />
    </DefaultLayout>
  );
};

export default KanhaVolunteeringPage;
